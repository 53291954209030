import { defineStore } from 'pinia'
import GroupService from '@/service/GroupService'
import { useUserStore } from '@/stores/';
import { uuid } from 'vue-uuid'
const groupService = new GroupService();
export const gStore = defineStore('group', {
    state: () => ({
        curruserrole: "",
        groupmembers: [],
        groupownerid: 0,
        groupposts: [],
        group: {},
        mygroups: [],
        error: null
    }),
    getters: {
      
    },
    actions: {
      initialize (gId) {
        try {
            if (!!gId){                  
                groupService.getDetail(gId).then( g => {
                    this.group = g
                }).catch(e => {
                    this.error = e
                })
            } else {
                this.group = {
                    id: 0,
                    groupname: '',
                    groupdescription: '',
                    avatar: `https://avatars.dicebear.com/api/jdenticon/${uuid.v4()}.svg`,
                    ispublic: 0,
                    isviewable: 0,
                    attributes: {
                        whocanadd: 0,
                        whocaninvite: 0,
                        allowmemberinvite: false,
                        allowmemberannouncement: false,
                        groupcolor: '4CAF50'
                    },
                    usergrouprole: null,
                    membercount: 0            
                }
            }
              
        } catch (err) {
            this.error = err
        }     
      },
      checkRole(id){
          
      }


    },
  })