import {createRouter, createWebHistory} from 'vue-router';
import { useUserStore } from '@/stores'
import { Auth } from 'aws-amplify';
// import { authGuard } from '@auth0/auth0-vue'
const devRoutes = [
    {
        path: '/formlayout',
        name: 'formlayout',
        component: () => import('@/components/demo/FormLayoutDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Form Layout' }],
        },
    },
    {
        path: '/invalidstate',
        name: 'invalidstate',
        component: () => import('@/components/demo/InvalidStateDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Invalid State' }],
        },
    },
    {
        path: '/input',
        name: 'input',
        component: () => import('@/components/demo/InputDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Input' }],
        },
    },
    {
        path: '/floatlabel',
        name: 'floatlabel',
        component: () => import('@/components/demo/FloatLabelDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
        },
    },
    {
        path: '/button',
        name: 'button',
        component: () => import('@/components/demo/ButtonDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Button' }],
        },
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('@/components/demo/TableDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Table' }],
        },
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('@/components/demo/ListDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'List' }],
        },
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: () => import('@/pages/demo/TimelineDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Timeline' }],
        },
    },
    {
        path: '/tree',
        name: 'tree',
        component: () => import('@/components/demo/TreeDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Tree' }],
        },
    },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('@/components/demo/PanelsDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Panel' }],
        },
    },
    {
        path: '/overlay',
        name: 'overlay',
        component: () => import('@/components/demo/OverlayDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Overlay' }],
        },
    },
    {
        path: '/media',
        name: 'media',
        component: () => import('@/components/demo/MediaDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Media' }],
        },
    },
    {
        path: '/menu',
        component: () => import('@/components/demo/MenuDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('@/components/menu/PersonalDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menu/seat',
                component: () => import('@/components/menu/SeatDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menu/payment',
                component: () => import('@/components/menu/PaymentDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menu/confirmation',
                component: () => import('@/components/menu/ConfirmationDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('@/components/demo/MessagesDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Messages' }],
        },
    },
    {
        path: '/file',
        name: 'file',
        component: () => import('@/components/demo/FileDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'File' }],
        },
    },
    {
        path: '/chart',
        name: 'chart',
        component: () => import('@/components/demo/ChartsDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Charts' }],
        },
    },
    {
        path: '/misc',
        name: 'misc',
        component: () => import('@/components/demo/MiscDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Misc' }],
        },
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('@/utilities/Icons.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Icons' }],
        },
    },
    {
        path: '/widgets',
        name: 'widgets',
        component: () => import('@/utilities/Widgets.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Widgets' }],
        },
    },
    {
        path: '/grid',
        name: 'grid',
        component: () => import('@/utilities/GridDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Grid System' }],
        },
    },
    {
        path: '/spacing',
        name: 'spacing',
        component: () => import('@/utilities/SpacingDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Spacing' }],
        },
    },
    {
        path: '/elevation',
        name: 'elevation',
        component: () => import('@/utilities/ElevationDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Elevation' }],
        },
    },
    {
        path: '/typography',
        name: 'typography',
        component: () => import('@/utilities/Typography.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Typography' }],
        },
    },
    {
        path: '/display',
        name: 'display',
        component: () => import('@/utilities/DisplayDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Display' }],
        },
    },
    {
        path: '/flexbox',
        name: 'flexbox',
        component: () => import('@/utilities/FlexBoxDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Flexbox' }],
        },
    },
    {
        path: '/text',
        name: 'text',
        component: () => import('@/utilities/TextDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Text' }],
        },
    },
    {
        path: '/crud',
        name: 'crud',
        component: () => import('@/pages/CrudDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Crud' }],
        },
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('@/pages/CalendarDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Calendar' }],
        },
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: () => import('@/pages/demo/Invoice.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Invoice' }],
        },
    },
    {
        path: '/help',
        name: 'help',
        component: () => import('@/pages/demo/Help.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Help' }],
        },
    },
    
    {
        path: '/empty',
        name: 'empty',
        component: () => import('@/components/demo/EmptyPage.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }],
        },
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('@/components/demo/Documentation.vue'),
        meta: {
            breadcrumb: [{ label: 'Documentation' }],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/demo/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('@/pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('@/pages/demo/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('@/pages/demo/Access.vue')
    },
	{
		path: '/wizard',
		name: 'wizard',
		component: () => import('@/pages/demo/Wizard.vue')
	},
    {
        path: '/blocks',
        name: 'blocks',
        component: () => import('@/components/demo/BlocksDemo.vue'),
        meta: {
            breadcrumb: [{ label: 'Blocks' }],
        },
    }
]
var routes = [
    {
        path: '/privacy',
        name: 'privacy',
        exact: true,
        component: () => import('@/pages/PrivacyPolicy.vue'),
        // beforeEnter: [authGuard],
        meta: {
            breadcrumb: [{ label: 'Privacy Policy' }],
        }
    },
    {
        path: '/auth',
        name: 'auth',
        exact: true,
        component: () => import('@/pages/Auth.vue'),
        // beforeEnter: [authGuard],
        meta: {
            breadcrumb: [{ label: 'Login or Sign Up' }],
        }
    },
    {
        path: '/home',
        name: 'home',
        exact: true,
        component: () => import('@/pages/Home.vue'),
        // beforeEnter: [authGuard],
        meta: {
            breadcrumb: [{ label: 'Home' }],
        }
    },
    {
        path: '/releasenotes',
        name: 'releasenotes',
        exact: true,
        component: () => import('@/pages/ReleaseNotes.vue'),
        // beforeEnter: [authGuard],
        meta: {
            breadcrumb: [{ label: 'Release Notes' }],
        }
    },
    {
        path: '/support',
        name: 'support',
        exact: true,
        component: () => import('@/pages/Support.vue'),
        // beforeEnter: [authGuard],
        meta: {
            auth: true,
            breadcrumb: [{ label: 'Support' }],
        }
    },
    {
        path: '/legend',
        name: 'legend',
        exact: true,
        component: () => import('@/pages/Legend.vue'),
        // beforeEnter: [authGuard],
        meta: {
            breadcrumb: [{ label: 'Legend' }],
        }
    },
    {
        path: '/admin',
        name: 'admin',
        exact: true,
        component: () => import('@/pages/Admin.vue'),
        // beforeEnter: [authGuard],
        meta: {
            auth: true,
            breadcrumb: [{ label: 'Admin' }],
        }
    },
    {
        path: '/',
        name: 'dashboard',
        exact: true,
        component: () => import('@/pages/Dashboard.vue'),
        // beforeEnter: [authGuard],
        meta: {
            auth: true,
            breadcrumb: [{ label: 'Dashboard' }],
        }
    },
    {
        path: '/connect',
        name: 'connect',
        exact: true,
        component: () => import('@/pages/Connect.vue'),
        // beforeEnter: authGuard,
        meta: {
            auth: true,
            breadcrumb: [{ label: 'Connect' }],
        }
    },
    {
        path: '/posts',
        name: 'Post',
        exact: true,
        component: () => import('@/pages/Post.vue'),
        // beforeEnter: authGuard,
        meta: {
            auth: true,
            breadcrumb: [{ label: 'Post' }],
        }
    },
    {
        path: '/post/:id',
        name: 'postdetail',
        exact: true,
        component: () => import('@/pages/PostDetail.vue'),
        // beforeEnter: authGuard,
        meta: {
            auth: true,
            breadcrumb: [{ parent: 'Posts', label: 'Post Detail'}],
        }
    },
    {
        path: '/user/:id',
        name: 'userdetail',
        exact: true,
        component: () => import('@/pages/UserDetail.vue'),
        // beforeEnter: authGuard,
        meta: {
            auth: true,
            breadcrumb: [{ label: 'User Detail'}],
        }
    },
    {
        path: '/groups',
        name: 'Groups',
        exact: true,
        component: () => import('@/pages/Groups.vue'),
        // beforeEnter: authGuard,
        meta: {
            auth: true,
            breadcrumb: [{ label: 'Groups' }],
        }
    },
    {
        path: '/group/:id',
        name: 'groupdetail',
        exact: true,
        component: () => import('@/pages/GroupDetail.vue'),
        // beforeEnter: authGuard,
        meta: {
            auth: true,
            breadcrumb: [{ parent: 'Groups', label: 'Group Detail'}],
        }
    },
    {
        path: '/group/:id/members',
        name: 'groupmemberlist',
        exact: true,
        component: () => import('@/pages/GroupMemberList.vue'),
        // beforeEnter: authGuard,
        meta: {
            auth: true,
            breadcrumb: [{ parent: 'Groups', label: 'Group Members'}],
        }
    },
    {
        path: '/friendlist/:id',
        name: 'friendlist',
        exact: true,
        component: () => import('@/pages/FriendListDetail.vue'),
        // beforeEnter: authGuard,
        meta: {
            auth: true,
            breadcrumb: [{ parent: 'Groups', label: 'FriendList Detail'}],
        }
    },
    {
        path: '/friendlists',
        name: 'Friend Lists',
        exact: true,
        component: () => import('@/pages/FriendLists.vue'),
        // beforeEnter: authGuard,
        meta: {
            auth: true,
            breadcrumb: [{ label: 'Friend Lists' }],
        }
    },
    {
        path: '/notifications',
        name: 'Notifications',
        exact: true,
        component: () => import('@/pages/Notifications.vue'),
        // beforeEnter: authGuard,
        meta: {
            auth: true,
            breadcrumb: [{ label: 'Notifications' }],
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        exact: true,
        component: () => import('@/pages/Profile.vue'),
        // beforeEnter: authGuard,
        meta: {
            auth: true,
            breadcrumb: [{ label: 'Profile' }],
        }
    },
    {
        path: '/upcomingteetimes',
        name: 'Upcoming Tee Times',
        exact: true,
        component: () => import('@/pages/UpcomingTeeTimes.vue'),
        // beforeEnter: authGuard,
        meta: {
            auth: true,
            breadcrumb: [{ label: 'Upcoming Tee Times' }],
        }
    },
    {
        path: '/watched',
        name: 'Watched Posts',
        exact: true,
        component: () => import('@/pages/WatchedPosts.vue'),
        // beforeEnter: authGuard,
        meta: {
            auth: true,
            breadcrumb: [{ label: 'Watched Posts' }],
        }
    }
    // {
    //     path: '/callback',
    //     name: 'callback',
    //     component: () => import('@/pages/Callback.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Pages', label: 'Callback' }],
    //     },
    // },
   
];


if (process.env.VUE_APP_NODE_ENV === 'development') routes = routes.concat(devRoutes);
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

router.beforeResolve( (to, from, next) => {
    if(to.meta.auth){
        Auth.currentAuthenticatedUser().then(() => {
            if(to.path == '/admin' && useUserStore().user.roleId != 1){
                next({
                    path: '/'
                })
            }else{
                next()
            }
        }).catch(() => {
            next({
                path: '/auth',
                query: { redirect: to.path }
            })
        })
    }else{
        next();
    }
})
export default router;