import axios from "axios";
import { store } from "../stores/index.js";
import { useUserStore } from "../stores/";

const apiServerUrl = process.env.VUE_APP_NODE_API_SERVER_URL;

export const callExternalApi = async (options) => {
  try {
    const userStore = useUserStore()
    const config = {
        url: `${options.server || apiServerUrl}${options.endpoint}`,
        data: options.data,
        method: options.method,
        headers: {
            "Content-Type": (!!options.headers?.contenttype) ?  options.headers.contenttype : "application/json",
            Authorization: (!!options.headers?.authorization) ? options.headers.authorization : `Bearer ${userStore.token}`,
            // ...options.headers
        },
        params: options.params
    // withCredentials: true
    };
    
    const response = await axios(config);
    const { data } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && response.data.message) {
        message = response.data.message;
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: error.message,
      },
    };
  }
};
