import { ref, watch, computed } from 'vue';
import { useCookie } from 'vue-cookie-next';

const allowCookies = ref();

export default function useCookies(gtag) {
  const cookie = useCookie();
  if (cookie.isCookieAvailable('gb_consent_cookie')) {
    allowCookies.value = cookie.getCookie('gb_consent_cookie') === 'true';
    gtag.optIn();
  } else {
    allowCookies.value = undefined;
  }

  watch(allowCookies, () => {
    if (allowCookies.value != undefined) {
      cookie.setCookie('gb_consent_cookie', allowCookies.value.toString(), {
        expire: new Date(2034, 1, 1),
      });
      if (allowCookies.value) {
        gtag.optIn();
      } else {
        gtag.optOut();
      }
    }
  });

  const showBanner = computed(() => {
    return allowCookies.value === undefined;
  });

  const okClicked = () => (allowCookies.value = true);

  return {
    allowCookies,
    showBanner,
    okClicked,
  };
}