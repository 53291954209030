<template>
	<div class="layout-topbar clearfix">
		<button class="layout-topbar-logo p-link" @click="goDashboard">
			<img id="layout-topbar-logo" alt="babylon-layout" src="/layout/images/logo-black.png"/>
		</button>

		<button class="layout-menu-button p-link" @click="onMenuButtonClick">
			<i class="pi pi-bars"></i>
		</button>

		<button id="topbar-menu-button" class="p-link" @click="onTopbarMenuButtonClick">
			<i class="pi pi-ellipsis-v"></i>
		</button>

		<!-- <button id="topbar-notification-menu-button" class="p-link" @click="onTopbarMenuButtonClick">
			<i v-badge.info="u.notificationcount" class="pi pi-bell p-overlay-badge" style="font-size: 1.25rem" />
		</button> -->

		<ul v-if="show" :class="topbarItemsClass">
			<li v-if="profileMode === 'popup' || horizontal"
				:class="['user-profile', {'active-topmenuitem': activeTopbarItem === 'profile'}]"
				@click="$emit('topbar-item-click',{originalEvent:$event,item:'profile'})">
				<button class="p-link">
					<img v-if="u.user.profile?.avatar" alt="babylon-layout" :src="u.user.profile?.avatar || ''"/>
					<span class="topbar-item-name">{{u.user.profile?.firstname}} {{u.user.profile?.lastname}}</span>
				</button>

				<transition name="layout-submenu-container">
					<ul class="fadeInDown" v-show="activeTopbarItem === 'profile'">
						<li role="menuitem">
							<button class="p-link" @click="goProfile">
								<i class="pi pi-user"></i>
								<span>Profile</span>
							</button>
						</li>
						<!---
						<li role="menuitem">
							<button class="p-link">
								<i class="pi pi-cog"></i>
								<span>Settings</span>
							</button>
						</li>
						--->
						<!---
						<li role="menuitem">
							<button class="p-link">
								<i class="pi pi-envelope"></i>
								<span>Message</span>
							</button>
						</li>
						--->
						<li role="menuitem">
							<button class="p-link" @click="goToNotifications">
								<i class="pi pi-bell"></i>
								<span>Notifications</span>
							</button>
						</li>
						<li role="menuitem">
							<button class="p-link" @click="logout">
								<i class="pi pi-sign-out"></i>
								<span>Sign Out</span>
							</button>
						</li>
					</ul>
				</transition>
			</li>
			<li :class="[{'active-topmenuitem': activeTopbarItem === 'notifications'}]"
				@click="$emit('topbar-item-click',{originalEvent:$event,item:'notifications'})">
				<button id="topbar-notification-menu-button" class="p-link">
					<i class="topbar-icon pi pi-bell"></i>
					<span class="topbar-item-name">Notifications</span>
					<span class="topbar-badge">{{u.notificationcount}}</span>
				</button>
				<transition name="layout-submenu-container">
					<ul v-if="!!u.notifications.rows" class="fadeInDown" v-show="activeTopbarItem === 'notifications'">
						<NotificationItem  v-for="(n,i) in u.notifications.rows.slice(0,5)" :key="n.id" :data="n" :idx="i" type="menu" />
						<Button class="p-button-primary p-button-outlined text-center" label="View All" @click="goToNotifications" />
					</ul>
				</transition>
			</li>
		</ul>
	</div>
</template>

<script>
import { useUserStore } from '@/stores/'
import { ref } from 'vue'
import NotificationItem from '@/components/NotificationItem.vue'

import { Auth } from 'aws-amplify';
	export default {
		setup () {
			const uStore = useUserStore()
			const u = ref(uStore)
			const show = ref(false);


			Auth.currentAuthenticatedUser().then(()=> {
				show.value = true
			}).catch((e) => {
				show.value = false
			})
			return {
				u,
				show
			}
		},
		props: {
			topbarMenuActive: Boolean,
			topbarNotificationMenuActive: Boolean,
			activeTopbarItem: String,
			profileMode: String,
			horizontal: Boolean
		},
		methods: {
			onMenuButtonClick(event) {
				this.$emit('menubutton-click', event);
			},
			onTopbarMenuButtonClick(event) {
				this.$emit('topbar-menubutton-click', event);
			},
			goDashboard(){
				window.location = "/#/"
			},
			async logout () {
				try {
					await Auth.signOut();
					useUserStore().$reset();
					this.$emit('authenticated',false)

				} catch (error) {
					console.log('error signing out: ', error);
				}
			},
			goProfile () {
				window.location = "/profile"
			},
			goToNotifications () {
				window.location = "/notifications"
			}
		},
		computed: {
			topbarItemsClass() {
				return ['topbar-menu fadeInDown', {
					'topbar-menu-visible': this.topbarMenuActive
				}];
			},
			topbarNotificationClass() {
				return ['topbar-notification-menu fadeInDown', {
					'topbar-notification-menu-visible': this.topbarNotificationMenuActive
				}];
			},
		},
		components: {
			NotificationItem
		}
	}
</script>
