import { defineStore } from 'pinia'
import PostService from '@/service/PostService'
import TeeTimeService from '@/service/TeeTimeService'

const postService = new PostService();
const ttService = new TeeTimeService();

import { useUserStore } from '@/stores/'
export const pStore = defineStore('post', {
    state: () => ({
        posts: []
        ,post: {}
        ,pendingpost: {}
        ,error: null
    }),
    getters: {
      
    },
    actions: {
        initialize (pId,typeId) {
            try {
                if (!!pId){                  
                    postService.getPostDetails(pId).then( p => {
                        this.post = p
                        this.pendingpost = {...p,pending:true}
                    }).catch(e => {
                        this.error = e
                    })
                } else {
                    const cId = useUserStore().user.profile.courseId
                    if (!!!typeId) typeId = 1;
                    const p = {            
                        id: 0
                        ,title: ''
                        ,description: ''
                        ,date: ''
                        ,timesofday: []
                        ,starttime: ''
                        ,attributes: {
                            gaugeinterest: true,
                            allowcomments: true,
                            allowteetimejoin: true,
                            showteetimedata: true,
                            prefcouples: false,
                            gambling: false,
                            prefhdcmin: null,
                            prefhdcmax: null,
                            otherscanviewresponses: true,
                            game: null,
                            wager: null,
                            expiration: null
                        }
                        ,courseId: cId
                        ,groupId: null
                        ,postaudienceId: 2
                        ,posttypeId: typeId
                        ,poststatusId: 1
                        ,teetimes: []
                        ,authorizedviewers: []
                        ,watcher: false
                        ,userpostrole: null
                        ,tentativetimes: []
                        ,comments: []
                    }
                    this.post = p;
                    this.pendingpost = p
                }   
            } catch (err) {
                this.error = err
            }       

      },
      
        async addTeeTime () {
            try {
                let tt;
                const ttInit = {
                    id: 0
                    ,numberofslotstotal: 4
                    ,teetime: new Date()
                    ,users: []
                    ,guests: []
                    ,isnew: true
                }
                this.post.teetimes.map(x => x.isnew = false)
                this.post.teetimes.push(ttInit)
                // if ( !!this.post.id ){
                //     tt = await ttService.addTT(this.post.id,ttInit)
                //     this.post.teetimes.push({
                //         ...tt
                //         ,users: []
                //         ,guests: []
                //     })
                // }else{
                    
                // }              

                
            } catch (err) {
                this.error = err
            }       

        },
        async deleteTeeTime (tt) {
            try {               
                if (tt.id > 0) {
                    await ttService.removeTT(this.post.id, tt.id).then(t => {
                        this.post.teetimes = this.post.teetimes.filter( t => {return t.id !== tt.id})
                    })
                }else{
                    this.post.teetimes.unshift()
                    this.post.teetimes = this.post.teetimes.filter( t => {return t !== tt})
                }           
                return true;
                
            } catch (err) {
                this.error = err
            }       

        },
        async getComments () {
            await postService.getComments(this.post.id).then(c => {
                this.post.comments = c
            }).catch(err => this.error = err)
        },

        async joinTT (pid,ttid,uid,isguest) {
            try {
            ttService.joinTT(pid,ttid,uid,isguest)
            } catch (err) {
                console.log(err)
                this.error = err
            }       

        }


    },
  })

// const ttService = new TeeTimeService();
// export const ttStore = defineStore('teetime', {
//     state: () => ({
//         tt: {            
//             id: 0
//             ,numberofslots: 4
//             ,teetime: null
//             ,golfers: {
//                 users: [],
//                 guests: []
//             }
//         }
//         ,error: null
//     }),
//     getters: {
        
//     },
//     actions: {
//         async initialize (ttId) {
//             try {
//                 if (!!ttId){
//                     await ttService.getTTDetail(ttId).then(tt => {
//                         this.tt = tt
//                     }).catch(e => this.error = e)                
//                 }
//                 return this.tt

                
//             } catch (err) {
//                 this.error = err
//             }       

//         }


//     },
// })