import { defineStore } from 'pinia'
import {callExternalApi} from "../service/ApiService"
import UserService from '@/service/UserService'
import NotificationService from '@/service/NotificationService'
import { ref } from 'vue'
const userService = new UserService()
const notificationService = new NotificationService()
export const uStore = defineStore('user', {
    state: () => ({ 
        userService: null,
        isAuthenticated: false,
        token: null,
        profile: null,
        pendingrequests: [],
        pendingapprovals: [],
        friends: [],
        blocked: [],
        loading: false,
        error: null,
        user: {},
        newUser: false,
        buddylist: {},
        friendlists: [],
        groups: [],
        selectedUser: {},   
        notificationcount: 0,
        notifications: []
    }),
    getters: {
         
    },
    actions: {
        async resetNotificationCount () {
            notificationService.markNotifications(true).then(_ => {
                this.notificationcount = 0
            })            
        },
        async checkNotifications () {
            const n = await notificationService.checkUnseenNotifications()
            this.notificationcount = n.count

            this.notifications = await notificationService.getNotifications();
        },
        async setUser (u) {            
            this.token = u.signInUserSession.accessToken.jwtToken;
			return await userService.initializeUser(u).then(async (data) => {
				if(data.newUser) this.newUser = true
				
				this.user = {...data.user}

                this.checkNotifications();
                this.setConnectLists();
			})
        },
        async initialize (uId) {
            try {
                const u = await userService.getUserData(uId);
                //set state
                this.selectedUser = u
                
                return this.selectedUser
                
            } catch (err) {
                this.error = err
            }
        },
        //setting some state for quick retrieval. shouldn't be anything to large
        setConnectLists () {
            userService.getConnections('pendingrequests').then(g => {			
                if(!!g) this.pendingrequests = g;
            })
            userService.getConnections('pendingapprovals').then(g => {
                if(!!g) this.pendingapprovals = g;
            })
            userService.getConnections('friends').then(g => {			
                if(!!g) this.friends = g
            })
            userService.getConnections('blocked').then(g => {	
                if(!!g) this.blocked = g
            })
            userService.getFriendLists().then(fl => {	
                if(!!fl) this.friendlists = fl
            })
            userService.getGroups(this.user.id).then(g => {
                if(!!g) this.groups = g
            })
            return;
        },
        getConnectList (status)  {
            return userService.getConnections(status).then(g => {	
                var retRef;		
                if(!!g) {
                    switch (status){
                        case 'pendingrequests':
                            this.pendingrequests = g
                            retRef = this.pendingrequests
                            break;
                        case 'pendingapprovals':
                            this.pendingapprovals = g
                            retRef = this.pendingapprovals
                            break;
                        case 'friends':
                            this.friends = g    
                            retRef = this.friends                        
                            break;
                        case 'blocked':
                            this.blocked = g
                            retRef = this.blocked
                            break;
                    }
                    return ref(retRef)
                }
            })
        },
        setGroupLists()  {
            // userService.getFriendLists(this.user.id).then(fl => {	
            //     if(!!fl) this.friendlists = fl
            // })
            userService.getGroups(this.user.id).then(g => {
                if(!!g) this.groups = g
            })
        } 

    },
  })
