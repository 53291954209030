<template>
<Dialog header="Golf Beacon Beta Testing" v-model:visible="display" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
        <h2>Welcome!</h2>
        <p>Thank you for participating in the BETA testing of Golf Beacon! </p>
        <p>You could run into bugs/issues that make the application difficult to use. When you do, please submit a ticket by going to the "Support" tab in the menu and I'll prioritize and take care of the issue ASAP..</p>
        <p>Currently, the application will only be web-accessible, but should be mobile friendly (please submit a ticket if something looks "weird"). Plans for dedicated, native mobile applications will be determined on user interest and engagement.</p>
        <p>Notifications will be sent to your email (for now). Let us know if you are being inundated. Also, let us know if you are receiving emails when you shouldn't or not receiving notifications when you expect you should.</p>
        <p>Start off by going to the Connect tab and connecting with your golf friends. Then <a href="https://discord.gg/hd2wrm66dG">join our discord</a> channel to help grow the community.</p>
        <p>Please do not hesitate to submit tickets for new features or if something does not seem user friendly. I NEED that kind of feedback to make the app better! So please don't be shy or afraid to offend.</p>
        <p>Thanks again! Happy golfing!</p>
        <p>Logan</p>
        <template #footer>
            <Button label="Let's Go" icon="pi pi-check" @click="display = false" autofocus />
        </template>
    </Dialog>
</template>

<script>
import { ref } from 'vue'
export default {

    setup () {
        const display = ref(true);

        return {
            display
        }
    }
}
</script>