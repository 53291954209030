
import { store } from "../stores/index.js";
import axios from "axios";
const apiServerUrl = process.env.VUE_APP_NODE_API_SERVER_URL;
import {callExternalApi} from "./ApiService"

export default class PostService {

    async getMyPosts() {
        const options = {
            endpoint: '/posts/manage',
            method: 'GET'
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }
    async getTypes() {
        const options = {
            endpoint: '/posts/types',
            method: 'GET'
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }
    async getPostsStatuses() {
        const options = {
            endpoint: '/posts/statuses',
            method: 'GET'
        }
          
        const r = await callExternalApi(options)

        var retArr = [];
        if(!!r.data){
            r.data.forEach(s => {                
                var obj = {}
                obj.label = s.statusname;
                obj.value = s.id;
                retArr.push(obj)
            })
        }
        //convert to array of objects with label and value
        return retArr
    }

    async createPost(postObj) {
        const options = {
            endpoint: '/posts/',
            method: 'POST',
            data: {
                postObj
            }
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }

    async updatePost(pId,postObj) {
        const options = {
            endpoint: `/posts/${pId}`,
            method: 'PUT',
            data: {
                postObj
            }
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }

    async getPostDetails(pId) {
        const options = {
            endpoint: `/posts/${pId}`,
            method: 'GET'
        }
          
        const r = await callExternalApi(options)

        if ( !!r.data ) return r.data
        
        throw new Error ('Unable to retrieve details... Try again later.')
        
    }
    
    async deletePost(pId) {
        const options = {
            endpoint: `/posts/${pId}`,
            method: 'Delete'
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }

    /* TEE TIMES */
    async getTeeTimeDetail(tId) {
        const options = {
            endpoint: `/posts/${pId}/teetimes`,
            method: 'GET'
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }

    async getPostAudiences() {
        const options = {
            endpoint: `/posts/postaudiences`,
            method: 'GET'
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }

    async userInterest(pid, isinterested) {
        const options = {
            endpoint: `/posts/interested`,
            method: 'POST',
            data: {
                isinterested,
                pid
            }
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }

    // Tentative time responses
    async getResponses(pid) {
        const options = {
            endpoint: `/posts/${pid}/tentativetimeresponses`,
            method: 'GET',
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }
    async createResponses(pid, tentativetimes) {
        const options = {
            endpoint: `/posts/${pid}/tentativetimeresponses`,
            method: 'POST',
            data: {
                tentativetimes
            }
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }
    async updateResponses(pid, tentativetimes) {
        const options = {
            endpoint: `/posts/${pid}/tentativetimeresponses`,
            method: 'PUT',
            data: {
                tentativetimes
            }
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }
    async deleteResponses(pid) {
        const options = {
            endpoint: `/posts/${pid}/tentativetimeresponses`,
            method: 'DELETE',
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }

    async setWatcher(pid,watch) {
        const options = {
            endpoint: `/posts/watch`,
            method: 'POST',
            data: {
                pid,
                watch
            }
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }

    /* COMMENTS */
    async getComments (pid) {
        const options = {
            endpoint: `/posts/${pid}/comments`,
            method: 'GET'
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }

    async createComment (pid,comment) {
        const options = {
            endpoint: `/posts/${pid}/comments`,
            method: 'POST',
            data: {
                comment
            }
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }

    async updateComment (pid,cid,comment) {
        const options = {
            endpoint: `/posts/${pid}/comments/${cid}`,
            method: 'PUT',
            data: {
                comment
            }
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }

    async deleteComment (pid,cid) {
        const options = {
            endpoint: `/posts/${pid}/comments/${cid}`,
            method: 'DELETE'
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }
}
