<template>

    <div class="layout-footer sticky bottom-0">
		<div class="grid flex flex-column md:flex-row justify-content-center align-items-center card min-w-full text-center">
			<p class="mr-2 mb-2 md:mb-0"> To help give you the best experience, we wish to track some data! For more details check our policy
                <router-link to="/privacy">here.</router-link>
            </p>

            <Button label="Opt-In" icon="pi pi-check" @click="okBannerClicked" class="p-button" autofocus />
		</div>
	</div>
</template>

<script>
export default {
    
    setup (_,{emit}) {

        const okBannerClicked = () => {
            emit('okclicked')
        }
        return {
            okBannerClicked
        }
    }
}
</script>