
import { store } from "../stores/index.js";
import axios from "axios";
const apiServerUrl = process.env.VUE_APP_NODE_API_SERVER_URL;
import {callExternalApi} from "./ApiService"

export default class GroupService {

    async createGroup(groupObj) {
        const options = {
            endpoint: '/groups',
            method: 'POST',
            data: {
                data: groupObj
            }
        }
          
        const r = await callExternalApi(options)
        return r.data
    }

    async updateGroup(gid,groupObj) {
        const options = {
            endpoint: `/groups/${gid}`,
            method: 'PUT',
            data: {
                data: groupObj
            }
        }
          
        const r = await callExternalApi(options)
        return r.data
    }
    // async getAllGroups(sortField,sortOrder,q,limit,offset) {
    //     const options = {
    //         endpoint: '/groups',
    //         method: 'GET',
    //         params: {
    //             sortField,sortOrder,q,limit,offset
    //         }   
    //     }
          
    //     const r = await callExternalApi(options)
    //     return r.data
    // }
    
    async getViewableGroups(qParams) {
        const options = {
            endpoint: '/groups',
            method: 'GET',
            params: qParams
        }
          
        const r = await callExternalApi(options)
        return r.data
    }

    async getGroupMembers(gId, limit, offset, sortField, sortOrder, q) {
        const options = {
            endpoint: `/groups/${gId}/memberships`,
            method: 'GET',
            params: {
                sortField,
                sortOrder,
                limit,
                offset,
                q
            }
        }
          
        const r = await callExternalApi(options)
        if (!!r.error) throw new Error('Issue retrieving group members...')
        return r.data
    }
    async getCurrentUserGroupRole(gId) {
        const options = {
            endpoint: `/groups/${gId}/role`,
            method: 'GET'
        }
          
        const r = await callExternalApi(options)
        if (!!r.error) throw new Error('Issue retrieving group members...')
        return r.data
    }
    //https://developer.box.com/reference/post-group-memberships/
    async addUserToGroup(groupObj,role,userObj) {
        const options = {
            endpoint: `/groupmemberships`,
            method: 'POST',
            data: {
                groupObj,
                role,
                userObj
            }
        }
          
        const r = await callExternalApi(options)
        return r.data
    }

    async getDetail(gId) {
        const options = {
            endpoint: `/groups/${gId}`,
            method: 'GET'
        }
          
        const r = await callExternalApi(options)
        if (!!r.error) throw new Error(r.error.message)
        return r.data
    }

    async getGroupTypes() {
        const options = {
            endpoint: `/groups/types`,
            method: 'GET'
        }
          
        const r = await callExternalApi(options)
        if (!!r.error) throw new Error('Issue retrieving group types...')
        return r.data
    }

    async requestToJoin(gid) {
        const options = {
            endpoint: `/groups/rtj`,
            method: 'POST',
            data: {
                gid
            }
        }
        const r = await callExternalApi(options)
        if (!!r.error) return r.error.message
        return r.data
    }

    async joinGroup(gid) {
        const options = {
            endpoint: `/groups/join`,
            method: 'POST',
            data: {
                gid
            }
        }
        const r = await callExternalApi(options)
        if (!!r.error) return r.error.message
        return r.data
    }

    async alterMembership(gid,uid,action) {
        const options = {
            endpoint: `/groups/altermembership`,
            method: 'PUT',
            data: {
                gid,
                uid,
                action
            }
        }
        const r = await callExternalApi(options)
        if (!!r.error) throw new Error('Issue adding/removing member...')
        return r.data
    }

    async inviteUsers(gid,ulist) {
        const options = {
            endpoint: `/groups/${gid}/invite`,
            method: 'POST',
            data: {
                ulist
            }
        }
        const r = await callExternalApi(options)
        if (!!r.error) return r.error.message
        return r.data
    }
    

}
