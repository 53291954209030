<template>
	<div class="layout-footer">
		<div class="grid">
			<div class="col-6">
				<a href="/" class="logo-container">
					<img src="/layout/images/logo-black.png" alt="babylon-layout"/>
				</a>
			</div>
			<div class="col-6 footer-icons">
				<button class="p-link" @click="router.push({ path: '/home'})">
					<i class="pi pi-home"></i>
				</button>
				<button class="p-link" @click="router.push({ path: '/support'})">
					<i class="pi pi-question-circle"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { useRouter } from 'vue-router'
	export default {
		name: "AppFooter",
		setup () {
			const router = useRouter()
			return {
				router
			}

		}
	}
</script>

<style scoped>

</style>