import {callExternalApi} from "./ApiService"

export default class TeeTimeService {
    async getAllTT (pid) {
        const options = {
          endpoint: `/posts/${pid}/teetimes`,
          method: 'GET'
        }
        
        const r = await callExternalApi(options)
        return r.data
    }

    async getTTDetail (pid,ttid) {
        const options = {
          endpoint: `/posts/${pid}/teetimes/${ttid}`,
          method: 'GET'
        }
        
        const r = await callExternalApi(options)
        return r.data
    }

    async addTT (pid,ttobj) {
        //ttobj has starttime attr
        const options = {
          endpoint: `/posts/${pid}/teetimes`,
          method: 'POST',
          data: {
            ttobj
          }
        }
        
        const r = await callExternalApi(options)
        return r.data
    }

    async updateTT (pid,ttid,ttobj) {
        //ttobj has starttime attr
        const options = {
          endpoint: `/posts/${pid}/teetimes/${ttid}`,
          method: 'PUT',
          data: {
            ttobj
          }
        }
        
        const r = await callExternalApi(options)
        return r.data
    }

    async removeTT (pid,ttid) {
        const options = {
          endpoint: `/posts/${pid}/teetimes/${ttid}`,
          method: 'DELETE'
        }
        
        const r = await callExternalApi(options)
        return r.data
    }
    async joinTT (pid,ttid,uid,isguest) {
        const options = {
          endpoint: `/posts/${pid}/teetimes/${ttid}/join/${uid}`,
          method: 'POST',
          data: {
            isguest
          }
        }
        
        const r = await callExternalApi(options)
        return r.data
    }
    async removeUserTT (pid,ttid,uid,isguest) {
        const options = {
          endpoint: `/posts/${pid}/teetimes/${ttid}/drop/${uid}`,
          method: 'DELETE',
          data: {
            isguest
          }
        }
        
        const r = await callExternalApi(options)
        return r.data
    }
    async updateTTUsers (pid,ttid,ttobj) {
        //ttobj has players attribute
        const options = {
          endpoint: `/posts/${pid}/teetimes/${ttid}/users`,
          method: 'PUT',
          data: {
              ttobj
          }
        }
        
        const r = await callExternalApi(options)
        return r.data
    }

    async getTTUsers (pid,ttid) {
        //ttobj has players attribute
        const options = {
          endpoint: `/posts/${pid}/teetimes/${ttid}/users`,
          method: 'GET'
        }
        
        const r = await callExternalApi(options)
        return r.data
    }
    async getAllTTUsers (pid) {
        //for avatar grouping?
        const options = {
          endpoint: `/posts/${pid}/teetimeusers`,
          method: 'GET'
        }
        
        const r = await callExternalApi(options)
        return r.data
    }

    isAssociated (uid, teetimes,isguest) {
      let flag = false;
      
      if(!!isguest){
        teetimes.forEach(tt => {
          flag = tt.guests.some( g => {
            return g.id == uid
          })
        })
      }else{
        teetimes.forEach(tt => {
          flag = tt.users.some( u => {
            return u.id == uid
          })
        })
      }
      
      return flag;
    }

}