<template>
<li v-if="msg" :class="`${idx%2 == 0 ? 'surface-100': 'surface-50'}`" class="grid pl-2 flex-row align-items-center m-1 min-w-full min-h-full notification-item" @click="goTo(msg.gototype,msg.gotoid)" role="menuitem"> 
    <div class="flex justify-content-between align-items-center">
        <span v-html="msg.template"></span>
        <Button v-if="type != 'menu'" class="p-button-primary p-button-outlined p-button-rounded sm:ml-2" icon="pi pi-arrow-right" @click="goTo(msg.gototype,msg.gotoid)"/>
    </div>
    <span class="text-sm col-12">{{ format(new Date(data.createdAt), 'Pp')}}</span>    
</li>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { format } from 'date-fns'
export default {
    props: {
        data: {
            type: Object
        },
        idx: {
            type: Number
        },
        max: {
            type: Number,
            default: 20
        },
        type: {
            type: String,
            default: 'normal'
        }
    },
    setup (props) {
        const router = useRouter()
        const msg = ref(null)
        const mapping = [
            {
                type: 'privateouting',
                template: `<strong>${props.data.notifier?.profile.firstname}</strong> has invited you on a private outing!`,
                gototype: 'post',
                gotoid: props.data.associatedpost?.id
            },
            {
                type: 'publicouting',
                template: `<strong>${props.data.notifier?.profile.firstname}</strong> has posted a public outing!`,
                gototype: 'post',
                gotoid: props.data.associatedpost?.id
            },
            {
                type: 'comment',
                template: `<strong>${props.data.notifier?.profile.firstname}</strong> has commented on <strong>${props.data.associatedpost?.title}</strong>!`,
                gototype: 'post',
                gotoid: props.data.associatedpost?.id
            },
            {
                type: 'groupadd',
                template: `You have been added to <strong>${props.data.associatedgroup?.groupname}</strong>`,
                gototype: 'group',
                gotoid: props.data.associatedgroup?.id
            },
            {
                type: 'grouppremote',
                template: `You have been promoted in <strong>${props.data.associatedgroup?.groupname}</strong>`,
                gototype: 'group',
                gotoid: props.data.associatedgroup?.id
            },
            {
                type: 'teetimeadd',
                template: `[<strong>${props.data.associatedpost?.title}</strong>] A tee time has been added to a post you watch or during a time you indicated availability!`,
                gototype: 'post',
                gotoid: props.data.associatedpost?.id
            },
            {
                type: 'teetimechange',
                template: `<strong>A tee time in <strong>${props.data.associatedpost?.title}</strong> that you have joined has been changed!`,
                gototype: 'post',
                gotoid: props.data.associatedpost?.id
            },
            {
                type: 'availabilityadded',
                template: `<strong>${props.data.notifier?.profile.firstname}</strong> has added their availability to <strong>${props.data.associatedpost?.title}</strong>!`,
                gototype: 'post',
                gotoid: props.data.associatedpost?.id
            },
            {
                type: 'groupjoinrequest',
                template: `<strong>${props.data.notifier?.profile.firstname}</strong> has requested to join <strong>${props.data.associatedgroup?.groupname}</strong>!`,
                gototype: 'group',
                gotoid: props.data.associatedgroup?.id
            },
            {
                type: 'groupinvite',
                template: `<strong>${props.data.notifier?.profile.firstname}</strong> has invited you to join <strong>${props.data.associatedgroup?.groupname}</strong>!`,
                gototype: 'group',
                gotoid: props.data.associatedgroup?.id
            },
            {
                type: 'friendrequest',
                template: `<strong>${props.data.notifier?.profile.firstname}</strong> has requested to connect!`,
                gototype: 'connect',
                gotoid: null
            },
            {
                type: 'friendaccept',
                template: `<strong>${props.data.notifier?.profile.firstname}</strong> has accepted your request!`,
                gototype: 'connect',
                gotoid: null
            },
        ];

        
        msg.value = mapping.find(m =>{ return m.type == props.data.type})
        

        const goTo = (type,id) => {
            switch (type){
                case 'connect':
                    router.push({ name: 'connect', params: { status: 'pendingapprovals' } })
                    break;
                case 'post':
                    router.push({ name: 'postdetail', params: { id } })
                    break;
                case 'group':
                    router.push({ name: 'groupdetail', params: { id } })
                    break;
            }
        }
        return {
            msg,
            goTo,
            format
        }
    }
}
</script>
<style scoped>
.notification-item {
    cursor: pointer;
}
</style>