<template>
    <Dialog header="Tool Tip" v-model:visible="display" @hide="closeTooltipDisplay" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
        <p>{{message}}</p>
        <template #footer>
            <Button label="Got It" icon="pi pi-check" @click="closeTooltipDisplay" autofocus />
        </template>
    </Dialog>
</template>

<script>
import { ref } from 'vue'
export default {
    props: {
        message: {
            type: String
        }
    },
    emits: ['closeTooltip'],
    setup (props, { emit }) {
        const display = ref(true)
        const closeTooltipDisplay = () => {
            display.value = false;
            emit('closeTooltip')
        }
        return {
            display,
            closeTooltipDisplay
        }
    }
}
</script>