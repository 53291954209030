<template>
	<div :class="containerClass" @click="onDocumentClick">		
        <Toast/>
		<ConfirmDialog></ConfirmDialog>
		<AppTopBar :topbarMenuActive="topbarMenuActive" :activeTopbarItem="activeTopbarItem" :horizontal="isHorizontal()" :profileMode="profileMode"
					@menubutton-click="onMenuButtonClick" @topbar-menubutton-click="onTopbarMenuButtonClick" @topbar-item-click="onTopbarItemClick" :key="tbK" @authenticated="triggerAuth"></AppTopBar>
		
		
		<Welcome :key="tbK" v-if="newUser"/> 
		
		
		<transition name="layout-menu-container">
			<div class="layout-menu-container" @click="onMenuClick" v-show="isMenuVisible()">
				<div class="layout-menu-logo grid flex justify-content-center" @click="$router.push({path: '/'})">
					<button class="p-link">
						<img id="layout-menu-logo" src="/layout/images/logo-black.png" alt="gb-logo" height="150"/>
					</button>
				</div>
				<div class="layout-menu-wrapper">
					<div class="menu-scroll-content">						
						<AppMenu v-if="!isLoading" :model="menu" :layoutMode="layoutMode" :active="menuActive" :mobileMenuActive="staticMenuMobileActive" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
					</div>
				</div>
			</div>
		</transition>

		<div class="layout-main">
			<AppBreadcrumb></AppBreadcrumb>
			<!-- <div class="layout-content bg-cover" :style="{backgroundImage:`url(/layout/images/background/${bgImg})`}">	 -->
			<div class="layout-content bg-cover" :style="{backgroundImage:`url(/layout/images/background/fw.jpg)`}">	
			<router-view v-if="!isLoading" @authenticated="triggerAuth" />
				
			<ProgressSpinner v-else />

			
			</div>
			<Cookies v-if="showBanner" @okclicked="okBannerClicked"/>
			<AppFooter/>
		</div>

<!---
		<AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" :darkMenu="darkMenu" @menu-color-change="onMenuColorChange"
					:profileMode="profileMode" @profile-mode-change="onProfileModeChange" :configActive="configActive" @config-click="onConfigClick" @config-button-click="onConfigButtonClick"></AppConfig>
--->
		<div v-if="staticMenuMobileActive" class="layout-mask"></div>
	</div>
</template>

<script>
import AppTopBar from '@/AppTopbar.vue';
import AppInlineProfile from '@/AppInlineProfile.vue';
import AppMenu from '@/AppMenu.vue';
import AppConfig from '@/AppConfig.vue';
import AppBreadcrumb from '@/AppBreadcrumb.vue';
import AppFooter from '@/AppFooter.vue';
import EventBus from '@/event-bus';
import Welcome from '@/components/Welcome.vue'
import Cookies from '@/components/Cookies.vue'
import UserService from "./service/UserService"
import { useUserStore } from '@/stores/'
import { storeToRefs } from 'pinia'
import { Auth } from 'aws-amplify';
import { uuid } from 'vue-uuid'
import useCookies from './useCookies';
import { inject } from 'vue'
export default {
	created () {
		this.gtag = inject('gtag'); 
		this.userService = new UserService();	
		this.userStore = useUserStore()	
	},
	async mounted () {	
		const { showBanner, okClicked } = useCookies(this.gtag)
		this.showBanner = showBanner
		this.okClicked = okClicked	
		
		const { newUser } = storeToRefs(this.userStore)
		this.newUser = newUser

		Auth.currentAuthenticatedUser().then((user)=> {
            this.isAuthenticated = true
			this.userStore.setUser(user).then(_ => {
				this.isLoading = false
				this.pollNotifications();
			});
			this.menu = this.getMenu()
			
        }).catch(() => {
			this.isLoading = false
			this.menu = this.getMenu()
		})

		this.menu = this.getMenu()
		const bgImArr = ['courtney-cook-SsIIw_MET0E-unsplash.jpg','golf.webp','mick-haupt-m0iXio5FF7M-unsplash.jpg','mk-s-WHf1wtNMMLU-unsplash.jpg','robert-ruggiero-pI6IaynZQ_I-unsplash.jpg','soheb-zaidi-awin-9RBlpE-unsplash.jpg','sugar-golf-mqQ0BuJ5dsA-unsplash.jpg']
		this.bgImg = bgImArr[Math.floor(Math.random()*bgImArr.length)];

		
	},
	data() {		
		return {
			newUser: false,
			isAuthenticated: false,
			bgImg: null,
			userService: null,
			tk: null,
			isLoading: true,
			layoutMode: 'static',
			staticMenuDesktopInactive: false,
			staticMenuMobileActive: false,
			overlayMenuActive: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			menuActive: false,
			darkMenu: true,
			profileMode: 'popup',
			profileExpanded: false,
			configActive: false,
			configClick: false,
			menu: [],
			tbK: 1,
			showBanner:false,
			gtag: null,
			okClicked: null,
			polling: null,
			userStore: null
		}
	},
	watch: {
		$route() {
			this.menuActive = false;
			this.$toast.removeAllGroups();
		},

	},
	methods: {
		okBannerClicked () {
			this.okClicked()
		},
		pollNotifications () {
			var count = 0;
			this.polling = setInterval( () => {
				this.userStore.checkNotifications()
				count++;
				if (count == 40) clearInterval(this.polling)
			}, 30000)
		},
		triggerAuth (e) {
			this.isAuthenticated = e
			this.menu = this.getMenu()
			this.tbK = uuid.v4()
			if (!e){
				this.$router.push({path: '/auth'})
			}
		},
		onDocumentClick() {
			if (!this.topbarItemClick) {
				this.activeTopbarItem = null;
				this.topbarMenuActive = false;
			}

			if (!this.menuClick) {
				if (this.isHorizontal() || this.isSlim()) {
					this.menuActive = false;
				}

				if (this.overlayMenuActive || this.staticMenuMobileActive) {
					this.hideOverlayMenu();
				}

				EventBus.emit('reset-active-index');
				this.unblockBodyScroll();
			}

			if(!this.profileClick && this.layoutMode !== 'static') {
				this.profileExpanded = false;
			}

			if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

			this.topbarItemClick = false;
			this.menuClick = false;
			this.profileClick = false;
			this.configClick = false;
		},
		onMenuButtonClick(event) {
			this.menuClick = true;
			this.topbarMenuActive = false;

			if (this.layoutMode === 'overlay') {
				this.overlayMenuActive = !this.overlayMenuActive;
			}

			if (this.isDesktop())
				this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
			else {
				this.staticMenuMobileActive = !this.staticMenuMobileActive;
				if (this.staticMenuMobileActive) {
					this.blockBodyScroll();
				} else {
					this.unblockBodyScroll();
				}
			}

			event.preventDefault();
		},
		onTopbarMenuButtonClick(event) {
			this.topbarItemClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
		onTopbarItemClick(event) {
			this.topbarItemClick = true;

			if (this.activeTopbarItem === event.item){
				this.activeTopbarItem = null;
				if (event.item === 'notifications'){
					//
					if (this.userStore.notificationcount > 0){
						this.userStore.resetNotificationCount();
					}
				}
			}else{
				this.activeTopbarItem = event.item;
			}
			event.originalEvent.preventDefault();
		},
		onMenuClick() {
			this.menuClick = true;
		},
		isMenuVisible() {
			if (this.isDesktop()) {
				if (this.layoutMode === 'static')
					return !this.staticMenuDesktopInactive;
				else if (this.layoutMode === 'overlay')
					return this.overlayMenuActive;
				else
					return true;
			} else {
				return true;
			}
		},
		onProfileClick(event) {
			this.profileClick = true;
			this.profileExpanded = !this.profileExpanded;
			if(this.isHorizontal() || this.isSlim()) {
				EventBus.emit('reset-active-index');
			}

			event.preventDefault();
		},
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
			this.staticMenuDesktopInactive = false;
			this.overlayMenuActive = false;

			if(this.isHorizontal()) {
				this.profileMode = 'popup'
			}
		},
		onMenuColorChange(menuColor) {
			this.darkMenu = menuColor;
		},
		onProfileModeChange(profileMode) {
			this.profileMode = profileMode;
		},
		isHorizontal() {
			return this.layoutMode === 'horizontal';
		},
		isSlim() {
			return this.layoutMode === 'slim';
		},
		hideOverlayMenu() {
			this.overlayMenuActive = false;
			this.staticMenuMobileActive = false;
		},
		isDesktop() {
			return window.innerWidth > 1024;
		},
		isMobile() {
			return window.innerWidth <= 640;
		},
		onMenuItemClick(event) {
			if (!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
				this.menuActive = false;
			}
		},
		onRootMenuItemClick() {
			this.menuActive = !this.menuActive;
		},
		onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick() {
            this.configActive = !this.configActive;
            this.configClick = true;
        },
		addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
		blockBodyScroll() {
            this.addClass(document.body, 'blocked-scroll');
		},
		unblockBodyScroll() {
            this.removeClass(document.body, 'blocked-scroll');
		},		
		getMenu() {
			return [
				{
					label: 'Golf Beacon', icon: 'pi pi-fw pi-home',
					items: [
						{label: 'Home', icon: 'pi pi-fw pi-home', to: '/home', visible: !this.isAuthenticated},
						// {label: 'Admin', icon: 'pi pi-fw pi-home', to: '/admin', visible: this.isAuthenticated},
						{label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/', visible: this.isAuthenticated},
						{label: 'Connect with Friends', icon: 'pi pi-arrows-h', to: '/connect', visible: this.isAuthenticated},
						{label: 'My Posts', icon: 'pi pi-fw pi-pencil', to: '/posts', visible: this.isAuthenticated},
						{label: 'Watched Posts', icon: 'pi pi-fw pi-eye', to: '/watched', visible: this.isAuthenticated},
						// {label: 'Upcoming Tee Times', icon: 'pi pi-fw pi-calendar', to: '/upcomingteetimes', visible: this.isAuthenticated},
						{label: 'Groups', icon: 'pi pi-fw pi-users', to: '/groups', visible: this.isAuthenticated},
						{label: 'Friend Lists', icon: 'pi pi-fw pi-users', to: '/friendlists', visible: this.isAuthenticated},
						{label: 'Edit Profile', icon: 'pi pi-fw pi-pencil', to: '/profile', visible: this.isAuthenticated},
						{label: 'Sign In', icon: 'pi pi-fw pi-sign-in', to: '/auth', visible: !this.isAuthenticated},
						{label: 'Sign Out', icon: 'pi pi-fw pi-sign-out', to: '/auth?signout=1', visible: this.isAuthenticated},
					]
				},
				{
					label: 'Support', icon: 'pi pi-fw pi-home',
					items: [
						{label: 'Color Legend', icon: 'pi pi-fw pi-palette', to: '/legend'},
						{label: 'Release Notes', icon: 'pi pi-fw pi-question-circle', to: '/releasenotes'},
						{label: 'Support', icon: 'pi pi-fw pi-info-circle', to: '/support'},
						{label: 'Privacy Policy', icon: 'pi pi-fw pi-lock', to: '/privacy'},
						{label: 'Join our Discord', icon: 'pi pi-fw pi-discord', url: "https://discord.gg/hd2wrm66dG"},
					]
				},
				{
					label: 'UI Kit', icon: 'pi pi-fw pi-sitemap', visible:process.env.VUE_APP_NODE_ENV === 'development',
					items: [
						{label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout',},
						{label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input',},
						{label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel",},
						{label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "/invalidstate",},
						{label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button', class: 'rotated-icon',},
						{label: 'Table', icon: 'pi pi-fw pi-table', to: '/table',},
						{label: 'List', icon: 'pi pi-fw pi-list', to: '/list',},
						{label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree',},
						{label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel',},
						{label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay',},
						{label: "Media", icon: "pi pi-fw pi-image", to: "/media",},
						{label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu',},
						{label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages',},
						{label: 'File', icon: 'pi pi-fw pi-file', to: '/file',},
						{label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart',},
						{label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc',},
					]
				},
				{
					label: "Utilities", icon:'pi pi-fw pi-globe', visible:process.env.VUE_APP_NODE_ENV === 'development',
					items: [
						{label: 'Display', icon:'pi pi-fw pi-desktop', to:'/display'},
						{label: 'Elevation', icon:'pi pi-fw pi-external-link', to:'/elevation'},
						{label: 'Flexbox', icon:'pi pi-fw pi-directions', to:'/flexbox'},
						{label: 'Icons', icon:'pi pi-fw pi-prime', to:'/icons'},
						{label: 'Widgets', icon:'pi pi-fw pi-star', to:'/widgets'},
						{label: 'Grid System', icon:'pi pi-fw pi-th-large', to:'/grid'},
						{label: 'Spacing', icon:'pi pi-fw pi-arrow-right', to:'/spacing'},
						{label: 'Typography', icon:'pi pi-fw pi-align-center', to:'/typography'},
						{label: 'Text', icon:'pi pi-fw pi-pencil', to:'/text'},
					]
				},
				{
					label: "UI Blocks", icon: "pi pi-building", visible:process.env.VUE_APP_NODE_ENV === 'development',
					items: [
						{label: "Free Blocks", icon: "pi pi-fw pi-eye", to: "/blocks", badge: "NEW"},
						{label: "All Blocks", icon: "pi pi-fw pi-globe", url: "https://www.primefaces.org/primeblocks-vue"}
					]
				},
				{
					label: 'Pages', icon: 'pi pi-fw pi-clone', visible:process.env.VUE_APP_NODE_ENV === 'development',
					items: [						
						{label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'},
						{label: 'Crud', icon: 'pi pi-fw pi-pencil', to: '/crud'},
						{label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', to: '/calendar'},
						{label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline'},
						{label: 'Landing', icon: 'pi pi-fw pi-user-plus', url: 'pages/landing.html', target: '_blank'},
						{label: 'Login', icon: 'pi pi-fw pi-sign-in', to: '/login'},
						{label: 'Invoice', icon: 'pi pi-fw pi-dollar', to: '/invoice'},
						{label: 'Help', icon: 'pi pi-fw pi-question-circle', to: '/help'},
						{label: 'Wizard', icon: 'pi pi-fw pi-star-fill', to: '/wizard'},
						{label: 'Error', icon: 'pi pi-fw pi-times-circle', to: '/error'},
						{label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', to: '/notfound'},
						{label: 'Access Denied', icon: 'pi pi-fw pi-lock', to: '/access'},
						{label: 'Empty', icon: 'pi pi-fw pi-circle-off', to: '/empty'}
					]
				},
				{
					label: 'Hierarchy', icon: 'pi pi-fw pi-align-left', visible:process.env.VUE_APP_NODE_ENV === 'development',
					items: [
						{
							label: 'Submenu 1', icon: 'pi pi-fw pi-align-left',
							items: [
								{
									label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
									items: [
										{ label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left' },
										{ label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left' },
										{ label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left' },
									]
								},
								{
									label: 'Submenu 1.2', icon: 'pi pi-fw pi-align-left',
									items: [
										{ label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left' },
										{ label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-align-left' }
									]
								},
							]
						},
						{
							label: 'Submenu 2', icon: 'pi pi-fw pi-align-left', 
							items: [
								{
									label: 'Submenu 2.1', icon: 'pi pi-fw pi-align-left',
									items: [
										{ label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left' },
										{ label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left' },
										{ label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-align-left' },
									]
								},
								{
									label: 'Submenu 2.2', icon: 'pi pi-fw pi-align-left',
									items: [
										{ label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left' },
										{ label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-align-left' }
									]
								},
							]
						}
					]
				},
				{
					label: 'Get Started', icon: 'pi pi-fw pi-download', visible:process.env.VUE_APP_NODE_ENV === 'development',
					items: [
						{
							label: 'Documentation', icon: 'pi pi-fw pi-file',to: '/documentation'
						},
						{
							label: 'Buy Now', icon: 'pi pi-fw pi-money-bill', url: ['https://www.primefaces.org/store']
						}
					]
				}
			]
		}
	},
	beforeDestroy () {
		clearInterval(this.polling)
	},
	computed: {
		containerClass() {
			return ['layout-wrapper', {
				'layout-horizontal': this.layoutMode === 'horizontal',
				'layout-overlay': this.layoutMode === 'overlay',
				'layout-static': this.layoutMode === 'static',
				'layout-slim': this.layoutMode === 'slim',
				'layout-static-inactive': this.staticMenuDesktopInactive,
				'layout-mobile-active': this.staticMenuMobileActive,
				'layout-overlay-active': this.overlayMenuActive,
				'layout-menu-dark': this.darkMenu,
				'layout-menu-light':!this.darkMenu,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
			}];
        },
        displayInlineProfile() {
            return this.profileMode === 'inline' && this.layoutMode !== 'horizontal'
        }
	},
	components: {
		'AppTopBar': AppTopBar,
		'AppConfig': AppConfig,
		'AppInlineProfile': AppInlineProfile,
		'AppMenu': AppMenu,
		'AppBreadcrumb': AppBreadcrumb,
		'AppFooter': AppFooter,
		Welcome,
		Cookies
		
	}
}
</script>

<style lang="scss">
	@import 'App.scss';
	// @import '@/assets/sass/theme/_theme.scss';
	// @import '@/assets/ass/layout/_layout.scss';
</style>
