import {callExternalApi} from "./ApiService"

export default class NotificationService {    
    async getNotifications (limit,offset) {
        const options = {
            endpoint: `/notifications`,
            method: 'GET',
            params: {                
                limit,
                offset
            }
        }
          
        
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }

    async markNotifications (markall,nid) {
        const options = {
            endpoint: `/notifications`,
            method: 'PUT',
            data: {
                markall,
                nid
            }
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }

    async checkUnseenNotifications () {
        const options = {
            endpoint: `/notifications`,
            method: 'GET',
            params: {
                unseen:true
            }
        }
          
        const r = await callExternalApi(options)
        if ( !!r.data ) return r.data
        throw new Error ('Something went wrong... Try again later.')
    }
}
