import { useUserStore } from "../stores/index.js";
// const userStore = useUserStore()
import axios from "axios";
const apiServerUrl = process.env.VUE_APP_NODE_API_SERVER_URL;
import {callExternalApi} from "./ApiService"

export default class UserService {
  async initializeUser (data) {
    const options = {
      endpoint: '/auth',
      data: data,
      method: 'POST'
    }
    
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async getGroups (uid) {
    const options = {
      endpoint: `/users/${uid}/groups`,
      method: 'GET'
    }
    
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
    // userStore.userGroups = r.data;
  }

  async getInvitedGroups () {
    const options = {
      endpoint: `/users/groupinvites`,
      method: 'GET'
    }
    
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async getAllUsers (friendsonly,sortfield, sortorder,q) {
    const options = {
      endpoint: `/users/`,
      method: 'GET',
      params: {
        relationshipstatus: (friendsonly) ? 3 : 0,
        sortfield,
        sortorder,
        q
      }
    }
  
    var r = await callExternalApi(options);
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async updateFriendStatus (u, s) {
    const options = {
      endpoint: `/users/${u}/relationship`,
      method: 'PUT',
      data: {
        status: s
      }
    }
  
    var r = await callExternalApi(options);
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  
  
  //profile methods
  async getProfileInformation () {
    
    const options = {
      endpoint: `/users/profile`,
      method: 'GET'
    }

    var r = await callExternalApi(options);
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async saveProfile (data) {
    // console.log(data)
    const options = {
      endpoint: `/users/profile`,
      method: 'PUT',
      data
    }

    var r = await callExternalApi(options);
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async checkUniqueUsername(u) {
    const options = {
      endpoint: `/users`,
      method: 'GET',
      params: {
        username: u
      }
    }

    var r = await callExternalApi(options);
    if ( !!r.data ) return r.data
    throw new Error ('Error')  
  }

  async getGenders() {
    const options = {
      endpoint: `/users/genders`,
      method: 'GET'
    }

    const g = [{"id":1,"gendername":"Male"},{"id":2,"gendername":"Female"}]
    return g;
    // var r = await callExternalApi(options);
    // console.log(r)
    // if ( !!r.data ) return r.data
    // throw new Error ('Error') 
  }

  async getBuddies(friendsonly,sortfield, sortorder,q) {
    const options = {
      endpoint: `/users`,
      method: 'GET',
      params: {
        relationshipstatus: (friendsonly) ? 3 : 0,
        sortfield,
        sortorder,
        q
      }
    }

    var r = await callExternalApi(options);
    if ( !!r.data ) return r.data
    throw new Error ('Error')  
  }


  

  async addFriendList( obj ) {
    const options = {
      endpoint: `/users/friendlists`,
      method: 'POST',
      data: {
        obj
      }
    }

    var r = await callExternalApi(options);
    if ( !!r.data ) return r.data
    throw new Error ('Error')  
  }
  async updateFriendList( obj ) {
    const options = {
      endpoint: `/users/friendlists`,
      method: 'PUT',
      data: {
        obj
      }
    }

    var r = await callExternalApi(options);
    if ( !!r.data ) return r.data
    throw new Error ('Error') 
  }

  async removeFriendList( obj ) {
    const options = {
      endpoint: `/users/friendlists`,
      method: 'DELETE',
      data: {
        obj
      }
    }

    var r = await callExternalApi(options);
    if ( !!r.data ) return r.data
    throw new Error ('Error')  
  }



  async getFriends() {
    const options = {
        endpoint: `/users/friends`,
        method: 'GET'
    }
      
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async getConnections(status, sortField, sortOrder, limit, offset, q) {
    const options = {
        endpoint: `/users/connections`,
        method: 'GET',
        params: {
          status,
          sortField,
          sortOrder,
          limit,
          offset,
          q
        }
    }
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }
  async getPendingRequests(uId) {
    const options = {
        endpoint: `/users/${uId}/pendingrequests`,
        method: 'GET'
    }
      
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async getBlockedUsers(uId) {
    const options = {
        endpoint: `/users/${uId}/blocked`,
        method: 'GET'
    }
      
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async getCourses(q) {
    const options = {
        endpoint: `/courses`,
        method: 'GET',
        params: {
          q
        }
    }
     
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }
  

  

  async addGuest(guestObj){
    const { name, email, handicap } = guestObj
    const options = {
        endpoint: `/users/guest`,
        method: 'POST',
        data: {
          name, email, handicap
        }
    }
      
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async getUserData(uid){
    const options = {
      endpoint: `/users/${uid}`,
      method: 'GET',

    }

    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async getAllUsers(){
    const options = {
      endpoint: `/users`,
      method: 'GET',

    }

    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }
  async getMyGroups(q) {
    const options = {
      endpoint: `/users/groups`,
      method: 'GET',
      params: {
        q
      }
    }
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }
  async getFriendLists() {
    const options = {
        endpoint: `/users/friendlists`,
        method: 'GET'
    }
      
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }
  
  async getFriendListDetail(fId) {
    const options = {
        endpoint: `/users/friendlists/${fId}`,
        method: 'GET'
    }
      
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }
  
  async updateFriendListMembers(fId,m){
    const options = {
        endpoint: `/users/friendlists/${fId}`,
        method: 'PUT',
        data: {
          members: m
        }
    }
      
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async createTicket(tickettype,body){
    const options = {
        endpoint: `/tickets`,
        method: 'POST',
        data: {
          tickettype
          ,body
        }
    }
      
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async getTickets(){
    const options = {
        endpoint: `/tickets`,
        method: 'GET'
    }
      
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async watchUser (uid,watch) {
    const options = {
        endpoint: `/users/watch`,
        method: 'PUT',
        data: {
          uid,
          watch
        }
    }
    
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }

  async removeAccount (uid,watch) {
    const options = {
        endpoint: `/users`,
        method: 'DELETE'
    }
    
    const r = await callExternalApi(options)
    if ( !!r.data ) return r.data
    throw new Error ('Error')
  }
}

